import { QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';

import type { NextPageWithLayout } from 'nextjs/types';

import { GoogleAnalytics } from 'nextjs-google-analytics';

import config from 'configs/app';
import useQueryClientConfig from 'lib/api/useQueryClientConfig';
import { AppContextProvider } from 'lib/contexts/app';
import { ChakraProvider } from 'lib/contexts/chakra';
import { ScrollDirectionProvider } from 'lib/contexts/scrollDirection';
import { SocketProvider } from 'lib/socket/context';
import theme from 'theme';
import Layout from 'ui/shared/layout/Layout';
import Web3ModalProvider from 'ui/shared/Web3ModalProvider';

import 'lib/setLocale';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// const ERROR_SCREEN_STYLES: ChakraProps = {
//   h: '100vh',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-start',
//   justifyContent: 'center',
//   width: 'fit-content',
//   maxW: '800px',
//   margin: '0 auto',
//   p: { base: 4, lg: 0 },
// };

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [ isClient, setIsClient ] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const queryClient = useQueryClientConfig();

  // const handleError = React.useCallback((error: Error) => {
  //   Sentry.captureException(error);
  // }, []);

  const getLayout = Component.getLayout ?? ((page) => <Layout>{ page }</Layout>);

  if (!isClient) {
    return null;
  }

  const GTAG_TESTNET = 'G-5R63E19TEZ';
  return (
    <ChakraProvider theme={ theme } cookies={ pageProps.cookies }>
      { /* cookies={ pageProps.cookies } */ }
      { /* <AppErrorBoundary
        { ...ERROR_SCREEN_STYLES }
        onError={ handleError }
      > */ }

      <GoogleAnalytics gaMeasurementId={ GTAG_TESTNET }/>
      <Web3ModalProvider>
        <AppContextProvider pageProps={ pageProps }>
          <QueryClientProvider client={ queryClient }>
            <ScrollDirectionProvider>
              <SocketProvider
                url={ `${ config.api.socket }${ config.api.basePath }/socket/v2` }
              >
                { getLayout(<Component { ...pageProps }/>) }
              </SocketProvider>
            </ScrollDirectionProvider>
            { /* <ReactQueryDevtools buttonPosition="bottom-left" position="left"/>  */ }
            <GoogleAnalytics/>
          </QueryClientProvider>
        </AppContextProvider>
      </Web3ModalProvider>
      { /* </AppErrorBoundary> */ }
    </ChakraProvider>
  );
}

export default MyApp;
