/* eslint-disable no-restricted-properties */
// import { getEnvValue } from "./utils";

const DEFAULT_CURRENCY_DECIMALS = 18;

// const chain = Object.freeze({
//   id: getEnvValue('NEXT_PUBLIC_NETWORK_ID'),
//   name: getEnvValue('NEXT_PUBLIC_NETWORK_NAME'),
//   shortName: getEnvValue('NEXT_PUBLIC_NETWORK_SHORT_NAME'),
//   currency: {
//     name: getEnvValue('NEXT_PUBLIC_NETWORK_CURRENCY_NAME'),
//     symbol: getEnvValue('NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL'),
//     decimals: Number(getEnvValue('NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS')) || DEFAULT_CURRENCY_DECIMALS,
//   },
//   governanceToken: {
//     symbol: getEnvValue('NEXT_PUBLIC_NETWORK_GOVERNANCE_TOKEN_SYMBOL'),
//   },
//   rpcUrl: getEnvValue('NEXT_PUBLIC_NETWORK_RPC_URL'),
//   isTestnet: getEnvValue('NEXT_PUBLIC_IS_TESTNET') === 'true',
//   verificationType: getEnvValue('NEXT_PUBLIC_NETWORK_VERIFICATION_TYPE') || 'mining',
// });

// const getEnvValue = (variable: string) => {
//   return process.env?.[variable];
// };
const chain = Object.freeze({
  id: process.env.NEXT_PUBLIC_NETWORK_ID,
  name: process.env.NEXT_PUBLIC_NETWORK_NAME,
  shortName: process.env.NEXT_PUBLIC_NETWORK_SHORT_NAME,
  currency: {
    name: process.env.NEXT_PUBLIC_NETWORK_CURRENCY_NAME,
    symbol: process.env.NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL,
    decimals:
      Number(process.env.NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS) ||
      DEFAULT_CURRENCY_DECIMALS,
  },
  governanceToken: {
    symbol: process.env.NEXT_PUBLIC_NETWORK_GOVERNANCE_TOKEN_SYMBOL,
  },
  rpcUrl: process.env.NEXT_PUBLIC_NETWORK_RPC_URL,
  isTestnet: process.env.NEXT_PUBLIC_IS_TESTNET,
  verificationType:
    process.env.NEXT_PUBLIC_NETWORK_VERIFICATION_TYPE || 'mining',
  // getEnvValue("NEXT_PUBLIC_NETWORK_VERIFICATION_TYPE") || "mining",
});

// eslint-disable-next-line no-console
console.log({ chain }, 'LOG 01');
export default chain;
